import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { formatDate } from 'utils/date';

interface ITrustedDeviceTableCard {
  data: ITableDataSource;
  longDateFormat: string;
}

export const TrustedDeviceTableCard = ({ data, longDateFormat }: ITrustedDeviceTableCard) => {
  const navigate = useNavigate();
  return (
    <Grid
      container
      sx={{
        border: `1px solid ${colors.gray200}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: colors.gray100,
      }}
    >
      <Grid item xs={6} lg={3} px={2} pt={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          Trusted Device
        </Typography>
        <Typography variant="body2">
          {data.title}, {data.createdDate ? formatDate(data.createdDate, longDateFormat) : ''}
        </Typography>
      </Grid>

      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
          m: {
            xs: 2,
            lg: 2,
          },
        }}
        pt={2}
      >
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" textTransform="uppercase">
            Actions:{' '}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            label="Remove"
            variant={EButtonVariants.outlined}
            onClick={() =>
              navigate(ERouteLinks.AccountRemoveDevice.replace(':id', data.id as string))
            }
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
