import React, { useEffect, useState } from 'react';
import { initialTranslationFormValues, ITranslationFormValues } from 'models/form';
import { FormInput } from 'components/molecules/FormInput';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslationFormSchema } from 'pages/TranslationsPage/schemaValidation';
import { useForm } from 'react-hook-form';
import { colors } from 'theme/colors';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { EMIMETypes } from 'constants/MIMETypes';
import { EAlertVariants } from 'components/atoms/Alert';
import UploadDropzoneField from 'components/atoms/UploadDropzoneField';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { EIconTypes } from 'constants/Icons';
import { StyledHeading } from '../styles';

const MAX_FILE_SIZE = 3;

export interface ITranslationForm {
  initialFormValues?: ITranslationFormValues | null;
  onCancel?: () => void;
  onSubmit: (arg: ITranslationFormValues) => void;
  isEdit?: boolean;
}

const TranslationForm = ({
  initialFormValues,
  onCancel,
  onSubmit,
  isEdit = false,
}: ITranslationForm) => {
  const { schema } = useTranslationFormSchema(isEdit);
  const { setAlert } = useAlert();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ITranslationFormValues>({
    defaultValues: initialFormValues || initialTranslationFormValues,
    resolver: yupResolver(schema),
  });
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [attachment, setAttachment] = useState<any>(null);
  const [clearFiles, setClearFiles] = useState(false);

  useEffect(() => {
    reset(initialFormValues || initialTranslationFormValues);
  }, [reset, initialFormValues]);

  const formChanged = (
    initialValues?: ITranslationFormValues | null,
    currentValues?: ITranslationFormValues | null,
  ) =>
    attachment ||
    initialValues?.name !== currentValues?.name ||
    initialValues?.nativeName !== currentValues?.nativeName;

  const onSubmitForm = async (data: any) => {
    setIsSubmitLoading(true);
    if (attachment) {
      const fileSizeInMB = attachment.size / 1024 / 1024;
      let formErrors = false;
      if (attachment && fileSizeInMB > MAX_FILE_SIZE) {
        formErrors = true;
        setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
          text: `The file size limit has been exceeded. The file size cannot exceed ${MAX_FILE_SIZE}MB.`,
          variant: EAlertVariants.error,
        });
      }
      if (![EMIMETypes.CSV, EMIMETypes.EXCEL_CSV].includes(attachment.type)) {
        formErrors = true;
        setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
          text: 'The file type is not correct. Only csv files are allowed.',
          variant: EAlertVariants.error,
        });
      }
      if (!formErrors) {
        onSubmit({
          name: data.name,
          nativeName: data.nativeName,
          translationsFile: attachment,
        });
      }
    } else {
      const formValues = {
        name: data.name,
        nativeName: data.nativeName,
      };
      if (formChanged(initialFormValues, formValues)) {
        onSubmit(formValues);
      } else {
        setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
          text: 'No data was changed.',
          variant: EAlertVariants.warning,
        });
      }
    }
    setIsSubmitLoading(false);
  };

  useEffect(() => {
    if (attachment) {
      setValue('translationsFile', attachment.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachment]);

  const clearAttachment = () => {
    setClearFiles(true);
    setValue('translationsFile', '');
    setValue('uploadedFile', '');
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid container item rowSpacing={0} columnSpacing={2} lg={12}>
        <StyledHeading>
          <Typography variant="h5" color={colors.primary900}>
            Keys Translations
          </Typography>
        </StyledHeading>
        <Grid item container rowSpacing={0} columnSpacing={2} lg={8}>
          <Grid item xs={12} lg={6}>
            <FormInput
              name="name"
              label="Language Name in English"
              control={control}
              errors={errors}
              setValue={setValue}
              isClearable
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormInput
              name="nativeName"
              label="Presented Language Name"
              control={control}
              errors={errors}
              setValue={setValue}
              isClearable
            />
          </Grid>
        </Grid>

        <Grid item container rowSpacing={0} columnSpacing={2} lg={8}>
          <Grid item xs={12}>
            <UploadDropzoneField
              onUpload={(files: any) => {
                setAttachment(files[0]);
                setValue('uploadedFile', files[0].name);
              }}
              shouldClearFiles={clearFiles}
              setFilesCleared={setClearFiles}
            />
          </Grid>
        </Grid>

        {errors?.translationsFile && (
          <Grid item lg={12} xs={12} style={{ marginTop: '10px' }}>
            <FormErrorLabel label={errors?.translationsFile?.message?.toString() as string} />
          </Grid>
        )}

        <Grid
          item
          container
          columnSpacing={2}
          lg={8}
          sx={{ alignItems: 'center', marginTop: '10px' }}
        >
          <Grid item xs="auto" sx={{ width: 'calc(100% - 48px) !important' }}>
            <FormInput
              name="uploadedFile"
              label="Uploaded File"
              control={control}
              errors={errors}
              disabled
            />
          </Grid>
          <Grid item xs="auto" sx={{ marginTop: '8px' }}>
            <Button
              icon={EIconTypes.delete}
              variant={EButtonVariants.outlined}
              sizePX={EButtonSizesPX.px32}
              onClick={() => clearAttachment()}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={0}>
          <Grid item xs={12} sm={6} lg={4}>
            <Button
              type="submit"
              fullWidth
              label="save"
              size={EButtonSizes.small}
              variant={EButtonVariants.contained}
              isLoading={isSubmitLoading}
            />
          </Grid>

          {onCancel && (
            <Grid item xs={12} sm={6} lg={4}>
              <Button
                fullWidth
                label="Cancel"
                size={EButtonSizes.small}
                variant={EButtonVariants.outlined}
                onClick={onCancel}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export { TranslationForm };
