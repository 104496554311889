import React, { ReactElement, useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { TableTopButtons } from 'components/atoms/TableTopButtons';
import Grid from '@mui/material/Grid';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'models/alertContext';
import { Alert } from 'components/atoms/Alert';
import { Box, Typography } from '@mui/material';
import { ActionTypes } from 'state/actions/alert';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { EPerPages } from 'models/table';
import { CardList } from 'components/molecules/CardList';
import { Table } from 'components/molecules/Table';
import { Pagination } from 'components/molecules/Pagination';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { formatDate } from 'utils/date';
import { colors } from 'theme/colors';
import { downloadFile } from 'requests/translation';
import { Loader } from 'components/atoms/Loader';
import { useTranslations } from './hooks';
import { TranslationTableCard } from './TranslationTableCard';
import { mapTranslationsToDataSource, translationsTableColumns } from './helpers';
import { StyledHeading, StyledLine } from './styles';

const TranslationsPage = (): ReactElement => {
  const navigate = useNavigate();
  const { nextPageAlert, clearAlert } = useAlert();
  const {
    translations,
    getTranslationsData,
    getDefaultTranslationData,
    paginator,
    defaultTranslation,
    getSystemKeysFileData,
  } = useTranslations();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<EPerPages>(EPerPages.perPage25);
  const { isDesktop } = useThemeBreakpoint();
  const { longDateFormat, getDateFormat } = useGlobalProperty();
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);

  useEffect(() => {
    if (!longDateFormat) {
      getDateFormat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function getSystemKeysFileDataInit() {
      await getSystemKeysFileData();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function getTranslationsDataInit() {
      setIsPaginationLoading(true);
      await getDefaultTranslationData();
      await getTranslationsData(currentPage, perPage);
      setIsDataLoading(false);
      setIsPaginationLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_NEXT_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleEditTranslations = (id: string) =>
    navigate(ERouteLinks.EditTranslation.replace(':id', id));

  const buttonsAction = () => (
    <Grid item container xs={12} md={12} lg={6} spacing={2}>
      {!isDesktop && (
        <>
          <StyledLine item xs={12} />
          <Grid item xs={12} sm={2} sx={{ alignItems: 'center' }}>
            <Typography variant="subtitle2">ACTIONS:</Typography>
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={isDesktop ? 12 : 10} container spacing={2}>
        <Grid item xs={12} sm={4} sx={{ paddingTop: isDesktop ? '16px' : '8px !important' }}>
          <Button
            label="update"
            variant={EButtonVariants.outlined}
            size={EButtonSizes.small}
            onClick={() =>
              navigate(
                ERouteLinks.UpdateSystemKeys.replace(
                  ':id',
                  defaultTranslation?.uniqueId || '',
                ),
              )
            }
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={12} sm={8} sx={{ paddingTop: isDesktop ? '16px' : '8px !important' }}>
          <Button
            label="download CSV FILE"
            variant={EButtonVariants.contained}
            size={EButtonSizes.small}
            onClick={() =>
              downloadFile(
                defaultTranslation?.uniqueId,
                defaultTranslation?.fileName || defaultTranslation?.name.toLowerCase(),
                false,
              )
            }
            fullWidth={true}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Breadcrumbs items={['Translations']} />
      <StyledPageTitle variant="h4">Translations</StyledPageTitle>

      <Grid
        container
        sx={{
          border: `1px solid ${colors.primary25}`,
          borderRadius: '4px',
          overflow: 'hidden',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: colors.primary25,
          }}
          px={2}
          py={1}
        >
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} lg={6}>
              <Typography variant="h4" sx={{ fontWeight: 600 }}>
                System Keys – Translation File
              </Typography>
            </Grid>
            {isDesktop && <>{buttonsAction()}</>}
          </Grid>
        </Grid>
        <Grid item container p={2} rowSpacing={2}>
          {defaultTranslation && (
            <>
              <Grid item xs={12} sm={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  Language
                </Typography>
                <Typography variant="body2">{defaultTranslation.name ?? '-'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  File Name
                </Typography>
                <Typography variant="body2">{defaultTranslation.fileName ?? '-'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  Last Update
                </Typography>
                <Typography variant="body2">
                  {defaultTranslation.lastModifiedDate
                    ? formatDate(defaultTranslation.lastModifiedDate, longDateFormat)
                    : '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  Updates Author
                </Typography>
                <Typography variant="body2">
                  {defaultTranslation.lastModifiedBy ?? '-'}
                </Typography>
              </Grid>
            </>
          )}
          {!isDesktop && <>{buttonsAction()}</>}
        </Grid>
      </Grid>

      <StyledHeading style={{ marginBottom: 0 }}>
        <Typography variant="h5" color={colors.primary900}>
          System Keys Translations
        </Typography>
      </StyledHeading>
      {nextPageAlert && (
        <Box mt={2} mb={2}>
          <Alert text={nextPageAlert.text} variant={nextPageAlert.variant} />
        </Box>
      )}
      <TableTopButtons>
        <Grid container justifyContent="flex-end" spacing={1}>
          <Grid item lg={6} xs={12}>
            <Button
              label="Add New Translation"
              variant={EButtonVariants.contained}
              icon={EIconTypes.plus}
              size={EButtonSizes.small}
              onClick={() => navigate(ERouteLinks.AddTranslation)}
              fullWidth={true}
            />
          </Grid>
        </Grid>
      </TableTopButtons>

      {isDataLoading ? (
        <Loader isLoading={true} />
      ) : (
        <>
          {isDesktop ? (
            <Table
              columns={translationsTableColumns({
                handleEdit: (id: string) => handleEditTranslations(id),
                dateFormat: longDateFormat,
              })}
              dataSource={mapTranslationsToDataSource(translations)}
            />
          ) : (
            <CardList
              items={mapTranslationsToDataSource(translations)}
              render={(data, index) => (
                <TranslationTableCard
                  data={data}
                  key={index}
                  dateFormat={longDateFormat}
                  editTranslations={(id: string) => handleEditTranslations(id)}
                />
              )}
            />
          )}

          {paginator && (
            <Pagination
              count={paginator.totalElements}
              onChangePage={(page) => setCurrentPage(page)}
              current={currentPage}
              perPage={perPage}
              onChangePerPage={(value) => setPerPage(value)}
              isLoading={isPaginationLoading}
            />
          )}
        </>
      )}
    </>
  );
};

export { TranslationsPage };
