import React, { useEffect, useState } from 'react';
import { colors } from 'theme/colors';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import Grid from '@mui/material/Grid';
import { Box, Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { useUserSetting } from 'pages/AccountPage/hooks';
import { useAuth } from 'models/authContext';
import { useDictionary } from 'models/dictionaryContext';
import { IParsedDictionary, parseDictionary } from 'helpers/dictionary';
import { fetchTrustedDevices } from 'requests/trustedDevice';
import { IAddTrustedDeviceResponse } from 'models/trustedDevice';
import { Table } from 'components/molecules/Table';
import { ERouteLinks } from 'models/route';
import { CardList } from 'components/molecules/CardList';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'models/alertContext';
import { Alert } from 'components/atoms/Alert';
import { ActionTypes } from 'state/actions/alert';
import { Loader } from 'components/atoms/Loader';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { StyledHeading } from './styles';
import { mapTrustedDevicesToDataSource, trustedDevicesTableColumns } from './helpers';
import { TrustedDeviceTableCard } from './TrustedDeviceTableCard';

const AccountPage = () => {
  const [trustedDevices, setTrustedDevices] = useState<IAddTrustedDeviceResponse[]>([]);
  const { longDateFormat, getDateFormat } = useGlobalProperty();
  const { isTablet, isDesktop, isMobile } = useThemeBreakpoint();
  const { userSetting, getUserSettingData } = useUserSetting();
  const { user } = useAuth();
  const { phoneCode, getPhoneCode } = useDictionary();
  const navigate = useNavigate();
  const { nextPageAlert, trustedDeviceAlert, clearAlert } = useAlert();
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

  useEffect(() => {
    getDateFormat();
  }, []);

  useEffect(() => {
    (async function getAccountDataInit() {
      if (user?.userId) {
        await getUserSettingData(user?.userId);
      }
      if (!phoneCode) {
        await getPhoneCode();
      }
      await fetchTrustedDevices((devices) => setTrustedDevices(devices));
      setIsDataLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_NEXT_PAGE_ALERT);
      clearAlert(ActionTypes.CLEAR_TRUSTED_DEVICE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const parsedPhoneCodes: IParsedDictionary = parseDictionary(phoneCode, 'name');
  const phoneNumber =
    userSetting &&
    userSetting.phoneNumber.codeDictionaryUniqueId &&
    parsedPhoneCodes?.[userSetting.phoneNumber.codeDictionaryUniqueId]
      ? `${parsedPhoneCodes?.[userSetting.phoneNumber.codeDictionaryUniqueId]} ${
          userSetting.phoneNumber.value
        }`
      : '';

  const renderActionButtons = (isTemplateFooter: boolean) => (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: isTemplateFooter ? 'flex-end' : 'space-between',
        alignItems: 'center',
        borderTop: {
          xs: `1px solid ${colors.primary25}`,
          lg: 'none',
        },
        pt: {
          xs: isTemplateFooter ? 0 : 2,
          lg: 0,
        },
        mt: {
          xs: isTemplateFooter ? 0 : 2,
          lg: 0,
        },
      }}
    >
      {isTablet && !isTemplateFooter && (
        <Grid item xs={12} sm={3} md={6} sx={{ mb: { xs: 2, sm: 0 }, pl: 2 }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            Actions:{' '}
          </Typography>
        </Grid>
      )}
      <Grid
        item
        container
        xs={12}
        sm={9}
        md={isTemplateFooter ? 12 : 6}
        spacing={2}
        sx={{
          display: {
            xs: isTemplateFooter ? 'none' : 'flex',
            lg: isTemplateFooter ? 'flex' : 'none',
          },
          pl: { xs: 2, sm: 0 },
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Grid item xs={isTemplateFooter ? 'auto' : 12} sm={isTemplateFooter ? 'auto' : 6}>
          <Button
            label="Edit"
            variant={EButtonVariants.outlined}
            onClick={() => navigate(ERouteLinks.AccountEdit)}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={isTemplateFooter ? 'auto' : 12} sm={isTemplateFooter ? 'auto' : 6}>
          <Button
            label="Change password"
            variant={EButtonVariants.outlined}
            onClick={() => navigate(ERouteLinks.AccountChangePassword)}
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Breadcrumbs items={['User Settings']} />
      <Grid
        container
        sx={{
          border: `1px solid ${colors.primary25}`,
          borderRadius: '4px',
          overflow: 'hidden',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: colors.primary25,
          }}
          px={2}
          py={1}
        >
          <Grid item xs={isMobile ? 12 : 4}>
            <Typography variant="h4" sx={{ fontWeight: 600 }}>
              User Data
            </Typography>
          </Grid>
          <Grid item xs={isMobile ? 12 : 8}>
            {renderActionButtons(true)}
          </Grid>
        </Grid>
        <Grid item container p={2} rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} sm={6} md={3} lg={12} xl={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              Username
            </Typography>
            <Typography
              variant="body2"
              sx={{ wordBreak: 'break-all', overflowWrap: 'anywhere' }}
            >
              {userSetting?.email}
            </Typography>
          </Grid>
          <Grid item xs={6} md={3} lg={4} xl={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              First Name
            </Typography>
            <Typography variant="body2">{userSetting?.firstName}</Typography>
          </Grid>
          <Grid item xs={6} md={3} lg={4} xl={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              Last Name
            </Typography>
            <Typography variant="body2">{userSetting?.lastName}</Typography>
          </Grid>
          <Grid item xs={6} md={3} lg={4} xl={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              Phone Number
            </Typography>
            <Typography variant="body2">{phoneNumber}</Typography>
          </Grid>
          {renderActionButtons(false)}
        </Grid>
      </Grid>

      {nextPageAlert && (
        <Box mt={2}>
          <Alert text={nextPageAlert.text} variant={nextPageAlert.variant} />
        </Box>
      )}

      {!!trustedDevices && (
        <>
          <StyledHeading>
            <Typography variant="body1">Trusted Devices</Typography>
          </StyledHeading>
          {trustedDeviceAlert && (
            <Box mb={2}>
              <Alert text={trustedDeviceAlert.text} variant={trustedDeviceAlert.variant} />
            </Box>
          )}
          {isDataLoading ? (
            <Loader isLoading={true} />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {isDesktop ? (
                <Table
                  columns={trustedDevicesTableColumns({
                    handleDelete: (id: string) =>
                      navigate(ERouteLinks.AccountRemoveDevice.replace(':id', id)),
                    longDateFormat,
                  })}
                  dataSource={mapTrustedDevicesToDataSource(trustedDevices)}
                />
              ) : (
                <CardList
                  items={mapTrustedDevicesToDataSource(trustedDevices)}
                  render={(data, index) => (
                    <TrustedDeviceTableCard
                      data={data}
                      longDateFormat={longDateFormat}
                      key={index}
                    />
                  )}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
export { AccountPage };
