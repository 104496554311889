import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Typography } from '@mui/material';
import { EPerPages } from 'models/table';
import { useForm } from 'react-hook-form';
import { perPages } from 'models/paginator';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { CurrentPageMobile } from 'components/molecules/Pagination/CurrentPageMobile';
import { Loader } from 'components/atoms/Loader';
import {
  PaginationPerPage,
  PaginationSummary,
  PaginationWrapper,
  PaginatorPerPageSelect,
  StyledPagination,
} from './styles';

interface IPagination {
  count: number;
  onChangePage: (page: number) => void;
  current: number;
  perPage: EPerPages;
  onChangePerPage: (perPage: EPerPages) => void;
  isLoading: boolean;
  isVisible?: boolean;
}

interface IPaginationForm {
  perPage: string;
}

const Pagination = ({
  count,
  onChangePage,
  current,
  perPage,
  onChangePerPage,
  isLoading,
  isVisible,
}: IPagination) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useForm<IPaginationForm>({
    defaultValues: { perPage: EPerPages.perPage25.toString() },
  });

  const { isDesktop } = useThemeBreakpoint();

  const watchPerPage: unknown = watch('perPage');

  useEffect(() => {
    onChangePage(1);
    onChangePerPage(watchPerPage as EPerPages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPerPage]);

  const currentCount = current * perPage;
  const summary = `${(current - 1) * perPage + 1}-${
    currentCount <= count ? currentCount : count
  } of ${count}`;

  if (!count) {
    return null;
  }

  const renderLoader = () => (
    <Box
      mb={2}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loader isLoading={true} />
    </Box>
  );

  const renderPagination = () => (
    <PaginationWrapper container spacing={2}>
      <PaginationPerPage item>
        <PaginatorPerPageSelect
          options={perPages}
          name="perPage"
          label="Per page"
          control={control}
          errors={errors}
        />
      </PaginationPerPage>
      <PaginationSummary item>
        <Typography variant="subtitle2">{summary}</Typography>
      </PaginationSummary>
      <Grid item sx={{ pr: !isDesktop ? 2 : 0 }}>
        {isDesktop ? (
          <StyledPagination
            count={Math.ceil(count / perPage)}
            page={current}
            onChange={(event, page) => onChangePage(page)}
            showFirstButton
            showLastButton
            variant="outlined"
            shape="rounded"
            boundaryCount={1}
            siblingCount={0}
          />
        ) : (
          <CurrentPageMobile
            count={count}
            perPage={perPage}
            page={current}
            onChange={(page) => onChangePage(page)}
          />
        )}
      </Grid>
    </PaginationWrapper>
  );

  return (
    <>
      {isLoading && isVisible && renderLoader()}
      {!isLoading && isVisible && renderPagination()}
    </>
  );
};
export { Pagination };
