import React, { ReactElement, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { FormInput } from 'components/molecules/FormInput';
import { useForm } from 'react-hook-form';
import { initialTwoFactorAuthFormValues, ITwoFactorAuthForm } from 'models/form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from 'pages/TwoFactorAuthPage/schemaValidation';
import { EButtonVariants } from 'constants/Buttons';
import { useAuth } from 'models/authContext';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import Logo from 'components/atoms/Logo';
import LogoImage from 'assets/images/Logo.svg';
import { Box } from '@mui/system';
import { AuthTemplate } from 'components/templates/AuthTemplate';
import { FormSelect } from 'components/molecules/FormSelect';
import { FormCheckbox } from 'components/molecules/FormCheckbox';
import { v4 as uuidv4 } from 'uuid';
import { FC_SYSTEM_DEVICE_ID } from 'pages/LoginPage';
import { useTrustedDevice } from 'models/trustedDeviceContext';
import { ERouteLinks } from 'models/route';
import { useLocation, useNavigate } from 'react-router-dom';
import { colors } from 'theme/colors';
import { RouterLink } from 'components/atoms/RouterLink';
import { browserName, fullBrowserVersion } from 'react-device-detect';
import { IAuthResponse } from 'models/auth';
import { IData } from 'pages/AuthPage';
import { Alert } from 'components/atoms/Alert';

export interface ITwoFactorAuthPageProps {
  email: string;
  password: string;
  phoneNumber: string;
  changeDataCallback: (p: IData) => void;
}

export const trustedDeviceOptions = [
  {
    label: '1',
    value: '41bc1262-6943-4633-897a-ea2f9fd9b9f1',
  },
  {
    label: '7',
    value: '1940f847-c8af-4b57-b835-8859a74d230e',
  },
  {
    label: '14',
    value: '2e9bae3d-b920-49af-ab59-e6ec6baa4e1b',
  },
  {
    label: '30',
    value: 'cbe22713-e676-4395-b66d-697ac4e34691',
  },
];

const TwoFactorAuthPage = ({
  email,
  password,
  phoneNumber,
  changeDataCallback,
}: ITwoFactorAuthPageProps): ReactElement => {
  const {
    control,
    getValues,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: initialTwoFactorAuthFormValues,
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const { verifyAuthCode, logIn } = useAuth();

  const { addTrustedDevice } = useTrustedDevice();
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isResendAuthCodeLoading, setIsResendAuthCodeLoading] = useState(false);
  const defaultRememberTimeValue = '41bc1262-6943-4633-897a-ea2f9fd9b9f1';
  const watchRememberDevice = watch('isRememberDevice');
  const { state: routerState }: any = useLocation();

  const onSubmit = handleSubmit(async (data) => {
    setIsLoginLoading(true);
    await verifyAuthCode(
      { email, enteredCodeValue: data.authCode },
      () => {
        if (data.isRememberDevice) {
          const deviceToken = uuidv4();
          addTrustedDevice(
            {
              deviceToken,
              title: `${browserName} ${fullBrowserVersion}`,
              trustedDeviceTimeOptionUniqueId: data.trustedDeviceTimeOptionUniqueId,
            },
            () => {
              localStorage.setItem(FC_SYSTEM_DEVICE_ID, deviceToken);
              navigate(ERouteLinks.Licences);
            },
          );
        }
        navigate(ERouteLinks.Licences);
      },
      (message) => {
        setError('authCode', { type: 'custom', message });
      },
    );
    setIsLoginLoading(false);
  });

  const [formFields, setFormFields] = useState<ITwoFactorAuthForm>(
    initialTwoFactorAuthFormValues,
  );

  useEffect(() => {
    if (watchRememberDevice) {
      setValue('trustedDeviceTimeOptionUniqueId', defaultRememberTimeValue);
    } else {
      setValue('trustedDeviceTimeOptionUniqueId', '');
    }
  }, [watchRememberDevice]);

  useEffect(() => {
    const subscription = watch(() => setFormFields(getValues()));
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    if (routerState?.isRedirectToTwoFactorAuthPage) {
      logIn(
        {
          email,
          password: routerState?.password,
          deviceToken: localStorage.getItem(FC_SYSTEM_DEVICE_ID) || null,
        },
        (res: IAuthResponse) => {
          if (res.phoneNumber) {
            changeDataCallback({
              email,
              password: routerState?.password,
              phoneNumber: res.phoneNumber,
            });
          }
        },
      );
    }
  }, []);

  return (
    <AuthTemplate>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        sm={11}
        md={7}
        xl={6}
        sx={{ p: { xs: 4 } }}
        px={2}
      >
        <Grid item xl={6} container justifyContent="center">
          <Grid
            item
            container
            justifyContent="center"
            sx={{ width: { xs: '120px', sm: '200px', xl: '240px' } }}
          >
            <Logo src={LogoImage} alt="Logo" />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Typography
              variant="button"
              textTransform="uppercase"
              color={colors.primary500}
              my={1}
            >
              Manager
            </Typography>
          </Grid>
          {routerState?.isRedirectToTwoFactorAuthPage && (
            <Grid item mt={2} mb={2}>
              <Alert text="The password has been changed successfully." />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h4" textAlign="center" mb={2} mt={4}>
              Two-Factor Authentication
            </Typography>
            <Typography variant="body1" textAlign="center" mb={2}>
              Please enter the code you received by SMS
              <br />
              {`on the following number: ${phoneNumber}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} xl={12}>
            <Box>
              <FormInput
                name="authCode"
                label="Authentication Code"
                placeholder="Enter received code"
                control={control}
                errors={errors}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: { xs: 32, sm: 40 },
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} xl={12} display="flex" alignItems="center">
            <Box>
              <FormCheckbox
                name="isRememberDevice"
                label="Remember this device for"
                control={control}
                errors={errors}
                withValidation={false}
              />
            </Box>
            <FormSelect
              name="trustedDeviceTimeOptionUniqueId"
              options={trustedDeviceOptions}
              control={control}
              errors={errors}
              disabled={!formFields.isRememberDevice}
              withValidation={false}
            />
            <Typography variant="caption" ml={2}>
              days
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} xl={12} container justifyContent="center">
            <Grid item xs={12} mt={3} mb={1}>
              <Button
                label="Verify"
                variant={EButtonVariants.contained}
                onClick={() => onSubmit()}
                isLoading={isLoginLoading}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                label="Resend Authentication Code"
                variant={EButtonVariants.outlined}
                isLoading={isResendAuthCodeLoading}
                onClick={async () => {
                  setIsResendAuthCodeLoading(true);
                  await logIn({
                    email,
                    password,
                    deviceToken: localStorage.getItem(FC_SYSTEM_DEVICE_ID) || null,
                  });
                  setIsResendAuthCodeLoading(false);
                }}
                fullWidth
              />
            </Grid>
            <Grid mt={3} container justifyContent="center">
              <RouterLink to={ERouteLinks.Auth} label="Back to log in" replace forceRefresh />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AuthTemplate>
  );
};
export { TwoFactorAuthPage };
