import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from 'theme/theme';
import { ReCaptchaProvider } from 'state/contexts/recaptcha';
import { FC_MANAGER_AUTH_ACCESS_TOKEN, FC_MANAGER_AUTH_REFRESH_TOKEN } from 'requests/auth';
import App from './App';
import reportWebVitals from './reportWebVitals';

const LOGOUT_TIMEOUT = 900000; // 15 min in milliseconds

const originalSetItem = localStorage.setItem;
localStorage.setItem = function (...rest) {
  const event = new Event('itemInserted');
  document.dispatchEvent(event);
  originalSetItem.apply(this, rest);
};

const originalRemoveItem = localStorage.removeItem;
localStorage.removeItem = function (...rest) {
  const event = new Event('itemRemoved');
  document.dispatchEvent(event);
  originalRemoveItem.apply(this, rest);
};

function idleLogout() {
  let timer: ReturnType<typeof setTimeout>;

  function handleActivity(forcedFlag: any) {
    if ((typeof forcedFlag === 'boolean' && !forcedFlag) || document.hidden) {
      clearTimeout(timer);
    }
  }

  const handleActivityFalse = () => handleActivity(false);
  const handleActivityTrue = () => handleActivity(true);

  document.addEventListener('visibilitychange', handleActivity);
  document.addEventListener('blur', handleActivityFalse);
  window.addEventListener('blur', handleActivityFalse);
  window.addEventListener('focus', handleActivityTrue);
  document.addEventListener('focus', handleActivityTrue);

  function logoutFunction() {
    localStorage.removeItem(FC_MANAGER_AUTH_ACCESS_TOKEN);
    localStorage.removeItem(FC_MANAGER_AUTH_REFRESH_TOKEN);
  }

  function resetTimer() {
    clearTimeout(timer);
    timer = setTimeout(logoutFunction, LOGOUT_TIMEOUT);
  }

  window.onload = resetTimer;
  window.onmousemove = resetTimer;
  window.onmousedown = resetTimer;
  window.ontouchstart = resetTimer;
  window.ontouchmove = resetTimer;
  window.onclick = resetTimer;
  window.onkeydown = resetTimer;
  window.addEventListener('scroll', resetTimer, true);
}

idleLogout();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ReCaptchaProvider>
        <App />
      </ReCaptchaProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
