import { IUserSettingRequest, IUserSettingResponse } from 'models/userSetting';
import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { isResponseError } from 'models/apiClient';

export const getUserSetting = async (id: string): Promise<IUserSettingResponse | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_USER_SETTING(id));

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

export const changePassword = async (currentPassword: string, newPassword: string) => {
  const { data } = await apiClient.put(ApiEndpoints.ACCOUNT_CHANGE_PASSWORD(), {
    currentPassword,
    newPassword,
  });

  return !isResponseError(data);
};

export const updateUserSetting = async (uniqueId: string, user: IUserSettingRequest) => {
  const { data } = await apiClient.put(ApiEndpoints.UPDATE_USER_SETTING(uniqueId), user);
  return !isResponseError(data);
};
