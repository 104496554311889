import React, { ReactElement, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { FormInput } from 'components/molecules/FormInput';
import { useForm } from 'react-hook-form';
import { initialForgotPasswordFormValues } from 'models/form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from 'pages/ForgotPasswordPage/schemaValidation';
import { EButtonVariants } from 'constants/Buttons';
import { RouterLink } from 'components/atoms/RouterLink';
import { IForgotPasswordBodyRequest } from 'models/password';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import Logo from 'components/atoms/Logo';
import LogoImage from 'assets/images/Logo.svg';
import { ERouteLinks } from 'models/route';
import { Box } from '@mui/system';
import { AuthTemplate } from 'components/templates/AuthTemplate';
import { usePassword } from 'models/passwordContext';
import { changeForgotPasswordRecaptcha } from 'requests/auth';
import { colors } from 'theme/colors';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Alert, EAlertVariants } from 'components/atoms/Alert';

const ForgotPasswordPage = (): ReactElement => {
  const [formStep, setFormStep] = useState(1);
  const [recaptchaError, setRecaptchaError] = useState<string>('');
  const [isBannedAccountError, setIsBannedAccountError] = useState<boolean>(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [isRecaptchaVerifiedSuccessfully, setIsRecaptchaVerifiedSuccessfully] =
    useState<boolean>(false);

  useEffect(() => setFormStep(1), []);

  useEffect(() => {
    (async function handleRecaptchaValidation() {
      if (recaptchaToken && !isRecaptchaVerifiedSuccessfully) {
        setRecaptchaError('');
        const res = await changeForgotPasswordRecaptcha(recaptchaToken);
        if (res) {
          setIsRecaptchaVerifiedSuccessfully(res.success);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recaptchaToken]);

  const toggleCaptchaBadge = (show: boolean) => {
    const badge = document.getElementsByClassName('grecaptcha-badge')[0];
    if (badge && badge instanceof HTMLElement) {
      badge.style.visibility = show ? 'visible' : 'hidden';
    }
  };

  useEffect(() => {
    toggleCaptchaBadge(true);
    return () => toggleCaptchaBadge(false);
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialForgotPasswordFormValues,
    resolver: yupResolver(schema),
  });
  const { forgotPassword, isForgotPasswordLoading } = usePassword();

  const onSubmit = handleSubmit(async (data: IForgotPasswordBodyRequest) => {
    if ((formStep === 1 && isRecaptchaVerifiedSuccessfully) || formStep === 2) {
      forgotPassword(
        data,
        () => setFormStep(2),
        () => setIsBannedAccountError(true),
      );
    } else {
      setRecaptchaError('Please verify that you are not a robot.');
    }
  });

  const handleChangeRecaptchaToken = (token: string) => {
    if (!recaptchaToken) {
      setRecaptchaToken(token);
    }
  };

  const renderForgotPasswordFormStep = (step: number) => {
    if (step === 1) {
      return (
        <>
          <Grid item xs={12}>
            <Typography variant="body1" textAlign="center" mb={2}>
              Please enter your email address to set
              <br />a new password to access FireCycle Manager.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <FormInput
                name="email"
                label="Email"
                placeholder="name@domain.com"
                control={control}
                errors={errors}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: { xs: 32, sm: 40 },
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            {recaptchaError && (
              <Box sx={{ minHeight: '24px' }}>
                <Typography variant="caption" color={colors.error700}>
                  {recaptchaError}
                </Typography>
              </Box>
            )}
          </Grid>
        </>
      );
    }
    if (step === 2) {
      return (
        <Grid item xs={12}>
          <Typography
            variant="body1"
            textAlign="center"
            mb={2}
            sx={{
              mt: {
                xs: 0,
                sm: 5,
              },
            }}
          >
            A reset password link has been sent to
            <br /> the provided email address. <br />
            Follow the link to set a new password <br />
            to access FireCycle Manager.
          </Typography>
          <Typography variant="body1" textAlign="center" mt={2} mb={4}>
            The link is valid for 24 hours.
          </Typography>
          <Typography variant="body1" textAlign="center" mb={2}>
            {`${"Didn't receive an email?"}`}
          </Typography>
        </Grid>
      );
    }
    return null;
  };

  return (
    <AuthTemplate>
      <GoogleReCaptcha onVerify={handleChangeRecaptchaToken} />
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        sm={10}
        md={7}
        lg={6}
        sx={{ p: { xs: 4 } }}
        px={2}
      >
        <Grid item xl={6} container justifyContent="center" alignItems="stretch">
          <Grid
            item
            container
            justifyContent="center"
            sx={{ width: { xs: '120px', sm: '200px', xl: '240px' } }}
          >
            <Logo src={LogoImage} alt="Logo" />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Typography
              variant="button"
              textTransform="uppercase"
              color={colors.primary500}
              my={1}
            >
              Manager
            </Typography>
          </Grid>
          {isBannedAccountError && (
            <Grid mt={2}>
              <Alert
                variant={EAlertVariants.error}
                text="Your account has been blocked. Please contact your system administrator."
              />
            </Grid>
          )}
          <Grid item xs={12} mt={4}>
            <Typography variant="h4" textAlign="center" mb={2}>
              Forgot Password?
            </Typography>
          </Grid>
          {renderForgotPasswordFormStep(formStep)}
          <Grid item xs={12} sm={8} xl={12} container justifyContent="center">
            <Grid item xs={12} my={3}>
              <Button
                label={formStep === 1 ? 'Reset password' : 'Resend link'}
                variant={formStep === 1 ? EButtonVariants.contained : EButtonVariants.outlined}
                onClick={() => onSubmit()}
                disabled={!isRecaptchaVerifiedSuccessfully}
                isLoading={isForgotPasswordLoading}
                fullWidth
              />
            </Grid>
            <RouterLink to={ERouteLinks.Auth} label="Return to login" replace />
          </Grid>
        </Grid>
      </Grid>
    </AuthTemplate>
  );
};
export { ForgotPasswordPage };
