import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlert } from 'models/alertContext';
import { initialUserFormValues, IUserFormValues } from 'models/form';
import { ActionTypes } from 'state/actions/alert';
import { EAlertVariants } from 'components/atoms/Alert';
import { StyledPageTitle } from 'theme/styles';
import TableFormWrapper from 'components/atoms/FormWrapper';
import { UserForm } from 'pages/UsersPage/UserForm';
import { getUser } from 'requests/user';
import { IUser } from 'models/user';
import { useUsers } from 'pages/UsersPage/hooks';
import { ERegExpTypes } from '../../../constants/RegExpTypes';

const EditUserPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<IUser | null>();
  const { handleEdit } = useUsers();
  const { setAlert, clearAlert } = useAlert();
  const [initialValues, setInitialValues] = useState<IUserFormValues | null>();

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_USER_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    (async function getUserDataInit() {
      if (id) {
        const response = await getUser(id);
        if (response) {
          const formInitialValues = { ...initialUserFormValues };
          formInitialValues.email = response.email;
          formInitialValues.firstName = response.firstName;
          formInitialValues.lastName = response.lastName;
          formInitialValues.phoneNumberCodeDictionaryUniqueId =
            response.phoneNumber.codeDictionaryUniqueId;
          formInitialValues.phoneNumberValue = response.phoneNumber.value;
          setInitialValues(formInitialValues);
          setUser(response);
        }
      }
    })();
  }, [id]);

  const checkIfUserMadeNoChanges = (data: IUserFormValues): boolean => {
    const regexExp = new RegExp(ERegExpTypes.startsWith0);
    const normalizedUserPhoneNumber = data.phoneNumberValue.replace(regexExp, '');
    const normalizedFormPhoneNumber = initialValues?.phoneNumberValue.replace(regexExp, '');
    return (
      data.firstName === initialValues?.firstName &&
      data.lastName === initialValues?.lastName &&
      data.phoneNumberCodeDictionaryUniqueId ===
        initialValues?.phoneNumberCodeDictionaryUniqueId &&
      normalizedUserPhoneNumber === normalizedFormPhoneNumber
    );
  };

  const handleSubmit = async (data: IUserFormValues) => {
    try {
      if (id) {
        if (checkIfUserMadeNoChanges(data)) {
          setAlert(ActionTypes.SET_USER_ALERT, {
            text: 'Data was not changed.',
            variant: EAlertVariants.warning,
          });
        } else {
          await handleEdit(id, data);
          navigate(ERouteLinks.Users);
        }
      }
    } catch (e: any) {
      setAlert(ActionTypes.SET_USER_ALERT, {
        text: e.response.data.message,
        variant: EAlertVariants.error,
      });
    }
  };

  return (
    <>
      <Breadcrumbs items={[{ label: 'Users', route: ERouteLinks.Users }, 'Edit User']} />
      <StyledPageTitle variant="h4">Edit User</StyledPageTitle>

      <TableFormWrapper>
        {user && (
          <UserForm
            onCancel={() => navigate(ERouteLinks.Users)}
            onSubmit={handleSubmit}
            initialFormValues={initialValues}
            isEdit={true}
          />
        )}
      </TableFormWrapper>
    </>
  );
};

export { EditUserPage };
