import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import TableFormWrapper from 'components/atoms/FormWrapper';
import { useNavigate } from 'react-router-dom';
import { initialTranslationFormValues, ITranslationFormValues } from 'models/form';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { Grid, Typography } from '@mui/material';
import { useTranslations } from 'pages/TranslationsPage/hooks';
import { useAlert } from 'models/alertContext';
import { Box } from '@mui/system';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { TranslationForm } from 'pages/TranslationsPage/TranslationForm';
import { downloadFile } from 'requests/translation';

const AddTranslationPage = () => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<ITranslationFormValues | null>();
  const { handleTranslationCreate } = useTranslations();
  const { setAlert, clearAlert, samePageAlert } = useAlert();

  const handleSubmit = async (data: ITranslationFormValues) => {
    try {
      if (!Object.prototype.hasOwnProperty.call(data, 'translationsFile')) {
        data.translationsFile = null;
      }
      await handleTranslationCreate(data);
      navigate(ERouteLinks.Translations);
    } catch (e: any) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: e.response.data.message,
        variant: EAlertVariants.error,
      });
    }
    return false;
  };

  useEffect(() => {
    const formInitialValues = { ...initialTranslationFormValues };
    setInitialValues(formInitialValues);
  }, []);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Breadcrumbs
        items={[
          { label: 'Translations', route: ERouteLinks.Translations },
          'Add New Translation',
        ]}
      />
      <StyledPageTitle variant="h4">Add New Translation</StyledPageTitle>
      <Typography variant="body2" sx={{ margin: '10px 0' }}>
        {`Download the main System Keys – Translation File with the complete list of keys and
        their English translations. Then, in the downloaded file, in the "TRANSLATION" column,
        add the translation for all keys in a specific language. Then upload the translated
        file below and click save.`}
      </Typography>

      <Grid item container xs={12} lg={8}>
        <Button
          fullWidth
          variant={EButtonVariants.outlined}
          icon={EIconTypes.download}
          label="download system keys – Translation File"
          onClick={() => {
            downloadFile();
          }}
        />
      </Grid>

      <TableFormWrapper>
        <TranslationForm
          onCancel={() => navigate(ERouteLinks.Translations)}
          onSubmit={handleSubmit}
          initialFormValues={initialValues}
        />
      </TableFormWrapper>
      {samePageAlert && (
        <Box mt={2}>
          <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
        </Box>
      )}
    </>
  );
};

export { AddTranslationPage };
