import React from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { FilterChip } from 'components/atoms/FilterChip';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { format } from 'date-fns';
import { EDateFormats } from 'constants/dates';
import { useNavigate } from 'react-router-dom';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import {
  StyledButtonsContainer,
  StyledFilterChipWrapper,
  StyledSelectedFiltersWrapper,
} from './styles';

export interface IValues {
  [key: string]: any;
}

interface ISelectedFilters {
  values: IValues;
  labels: IValues;
  dictionaries?: IValues;
  noPreset?: boolean;
  handleDelete: (arg?: string) => void;
  saveRoute?: string;
  excludes?: string[];
}

const SelectedFilters = ({
  values,
  labels,
  dictionaries,
  handleDelete,
  saveRoute,
  excludes,
  noPreset = false,
}: ISelectedFilters) => {
  const navigate = useNavigate();
  const { isDesktop, isMobile } = useThemeBreakpoint();

  if (excludes) {
    values = Object.fromEntries(
      Object.entries(values).filter((item) => !excludes.includes(item[0])),
    );
  }

  const shouldDisplay = Object.values(values).some((item) => !!item);

  if (!shouldDisplay) {
    return null;
  }

  values = Object.fromEntries(
    Object.entries(values).map((item) => {
      if (item[1] instanceof Date) {
        item[1] = format(item[1], EDateFormats.date);
      }
      return item;
    }),
  );

  const handleClearAll = () => {
    handleDelete();
  };

  return (
    <StyledSelectedFiltersWrapper
      container
      rowGap={1}
      flexWrap={isDesktop ? 'nowrap' : 'wrap'}
    >
      <Grid item>
        <Typography variant="body2">Selected Criteria:</Typography>
      </Grid>
      <StyledFilterChipWrapper item>
        {Object.entries(values)
          .filter((item) => item[1] && labels?.[item[0]])
          .map((item) => (
            <FilterChip
              key={item[0]}
              name={item[0]}
              label={labels?.[item[0]] ? labels?.[item[0]] : ''}
              values={dictionaries?.[item[0]]?.[item[1]] || item[1]}
              handleDelete={handleDelete}
            />
          ))}
      </StyledFilterChipWrapper>
      <StyledButtonsContainer
        container
        item
        xs={12}
        lg="auto"
        spacing={2}
        flexWrap={isMobile ? 'wrap' : 'nowrap'}
        maxWidth={isDesktop ? 'fit-content' : '100%'}
      >
        <Grid item xs={12} sm={!noPreset ? 6 : 12} lg="auto">
          <Button
            variant={EButtonVariants.text}
            label="Clear all"
            icon={EIconTypes.close}
            size={EButtonSizes.small}
            onClick={handleClearAll}
            fullWidth={!isDesktop}
          />
        </Grid>
        {!noPreset && (
          <Grid item xs={12} sm={6} lg="auto">
            <Button
              id="save_filter"
              variant={EButtonVariants.outlined}
              label="Save Filter"
              size={EButtonSizes.small}
              onClick={() => (saveRoute ? navigate(saveRoute) : {})}
              fullWidth={!isDesktop}
            />
          </Grid>
        )}
      </StyledButtonsContainer>
    </StyledSelectedFiltersWrapper>
  );
};

export { SelectedFilters };
