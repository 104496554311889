import React, { useEffect, useState } from 'react';
import { initialUserSearchFormValues, ISearchUserFormValues } from 'models/form';
import { useForm } from 'react-hook-form';
import { FormInput } from 'components/molecules/FormInput';
import { FormSelect } from 'components/molecules/FormSelect';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { SelectedFilters } from 'components/organisms/SelectedFilters';
import { ESearchUserFormLabelsTypes } from 'constants/SearchFormLabelsTypes';
import { prepareFilters } from 'helpers/filters';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import Grid from '@mui/material/Grid';
import { StyledAdvancedSearch } from 'theme/styles';

interface IUserSearchForm {
  onSubmit: (arg: ISearchUserFormValues) => void;
  isSearchButtonDisabled?: boolean;
}

const statusData = [
  { label: 'Active', value: 'true' },
  { label: 'Inactive', value: 'false' },
];

const UserSearchForm = ({ onSubmit, isSearchButtonDisabled = false }: IUserSearchForm) => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<ISearchUserFormValues>({
    defaultValues: initialUserSearchFormValues,
  });
  const [values, setValues] = useState<ISearchUserFormValues>(initialUserSearchFormValues);

  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    if (name) {
      reset({ ...getValues(), [name]: '' });
    } else {
      reset(initialUserSearchFormValues);
    }

    if (refreshResults) {
      onSubmit(getValues());
    }
  };

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as ISearchUserFormValues);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledAdvancedSearch
          container
          justifyContent="space-between"
          spacing={2}
          columns={10}
        >
          <Grid item lg={2} sm={5} xs={10}>
            <FormInput
              name="email"
              label={ESearchUserFormLabelsTypes.email}
              control={control}
              errors={errors}
              setValue={setValue}
              isClearable
            />
          </Grid>
          <Grid item lg={2} sm={5} xs={10}>
            <FormInput
              name="firstName"
              label={ESearchUserFormLabelsTypes.firstName}
              control={control}
              errors={errors}
              setValue={setValue}
              isClearable
            />
          </Grid>
          <Grid item lg={2} sm={5} xs={10}>
            <FormInput
              name="lastName"
              label={ESearchUserFormLabelsTypes.lastName}
              control={control}
              errors={errors}
              setValue={setValue}
              isClearable
            />
          </Grid>
          <Grid item lg={2} sm={5} xs={10}>
            <FormInput
              name="phoneNumber"
              label={ESearchUserFormLabelsTypes.phoneNumber}
              control={control}
              errors={errors}
              setValue={setValue}
              isClearable
            />
          </Grid>

          <Grid item lg={2} sm={5} xs={10}>
            <FormSelect
              options={statusData}
              name="status"
              canBeEmpty={true}
              label={ESearchUserFormLabelsTypes.status}
              control={control}
              setValue={setValue}
              errors={errors}
            />
          </Grid>
          <Grid
            item
            container
            columns={10}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Grid item lg={3.9} xs={10} alignSelf="center">
              <Button
                variant={EButtonVariants.contained}
                size={EButtonSizes.small}
                disabled={isSearchButtonDisabled}
                label="Search"
                type="submit"
                fullWidth={true}
                sx={{ mb: 2 }}
              />
            </Grid>
          </Grid>
        </StyledAdvancedSearch>
      </form>
      <SelectedFilters
        values={values}
        labels={ESearchUserFormLabelsTypes}
        handleDelete={handleResetField}
        dictionaries={{
          status: prepareFilters(statusData, 'value', 'label'),
        }}
        noPreset
      />
    </SearchWrapper>
  );
};

export { UserSearchForm };
