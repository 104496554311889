import * as yup from 'yup';
import { ERegExpTypes } from 'constants/RegExpTypes';

export const schema = yup.object({
  currentPassword: yup
    .string()
    .required('This field is required.')
    .min(8, 'Current Password incorrect.')
    .max(64, 'Current Password incorrect.')
    .test('password-validation', 'Current Password incorrect.', (value) => {
      if (value) {
        const regex = new RegExp(ERegExpTypes.password);
        return regex.test(value);
      }
      return true;
    }),
  newPassword: yup
    .string()
    .required('This field is required.')
    .min(
      8,
      'Passwords must contain between 8 to 64 characters and at least one uppercase and special character.',
    )
    .max(
      64,
      'Passwords must contain between 8 to 64 characters and at least one uppercase and special character.',
    )
    .test(
      'password-validation',
      'Passwords must contain between 8 to 64 characters and at least one uppercase and special character.',
      (value) => {
        if (value) {
          const regex = new RegExp(ERegExpTypes.password);
          return regex.test(value);
        }
        return true;
      },
    ),

  newPasswordRepeat: yup
    .string()
    .required('This field is required.')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match.'),
});
