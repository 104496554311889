import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import { Box, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from 'models/authContext';
import { useUserSetting } from 'pages/AccountPage/hooks';
import { useDictionary } from 'models/dictionaryContext';
import { useNavigate } from 'react-router-dom';
import { IUserSettingResponse } from 'models/userSetting';
import { FormInput } from 'components/molecules/FormInput';
import { FormSelect } from 'components/molecules/FormSelect';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import Grid from '@mui/material/Grid';
import {
  FC_MANAGER_AUTH_ACCESS_TOKEN,
  FC_MANAGER_AUTH_REFRESH_TOKEN,
  refreshAuthToken,
} from 'requests/auth';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { schema } from './schemaValidation';
import { ERegExpTypes } from '../../../constants/RegExpTypes';

interface IAccountFormValues {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  prefix: string;
}

const initialFormValues = {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  prefix: '',
};

const mapAccountToInitialFormValues = (account: IUserSettingResponse) => ({
  email: account.email,
  firstName: account.firstName,
  lastName: account.lastName,
  phoneNumber: account?.phoneNumber?.value,
  prefix: account?.phoneNumber?.codeDictionaryUniqueId,
});

const EditAccountPage = () => {
  const navigate = useNavigate();
  const { user, getUser } = useAuth();
  const { userSetting, getUserSettingData, updateUserSettingData } = useUserSetting();
  const { phoneCode, getPhoneCode } = useDictionary();
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const { setAlert, clearAlert, samePageAlert } = useAlert();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IAccountFormValues>({
    defaultValues: initialFormValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!phoneCode) {
      getPhoneCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    [],
  );

  useEffect(() => {
    reset(userSetting ? mapAccountToInitialFormValues(userSetting) : initialFormValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, initialFormValues, userSetting]);

  useEffect(() => {
    (async function init() {
      if (user?.userId) {
        await getUserSettingData(user?.userId);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasUserMadeNoChanges = (data: IAccountFormValues): boolean => {
    const regexExp = new RegExp(ERegExpTypes.startsWith0);
    const normalizedUserPhoneNumber = data.phoneNumber.replace(regexExp, '');
    const normalizedFormPhoneNumber = userSetting?.phoneNumber.value.replace(regexExp, '');
    return (
      data.firstName === userSetting?.firstName &&
      data.lastName === userSetting?.lastName &&
      data.prefix === userSetting?.phoneNumber.codeDictionaryUniqueId &&
      normalizedUserPhoneNumber === normalizedFormPhoneNumber
    );
  };

  const onSubmitForm = async (data: IAccountFormValues) => {
    if (userSetting) {
      if (hasUserMadeNoChanges(data)) {
        setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
          text: 'Data was not changed.',
          variant: EAlertVariants.warning,
        });
      } else {
        setSubmitLoading(true);
        const response = await updateUserSettingData(userSetting.uniqueId, {
          firstName: data.firstName,
          lastName: data.lastName,
          phoneNumber: {
            codeDictionaryUniqueId: data.prefix,
            value: data.phoneNumber,
          },
        });

        setSubmitLoading(false);

        const refreshToken = localStorage.getItem(FC_MANAGER_AUTH_REFRESH_TOKEN) || '';
        const res = await refreshAuthToken({
          refreshToken,
        });
        if (res?.accessToken && res?.refreshToken) {
          localStorage.setItem(FC_MANAGER_AUTH_ACCESS_TOKEN, res.accessToken);
          localStorage.setItem(FC_MANAGER_AUTH_REFRESH_TOKEN, res.refreshToken);
          getUser();
        }

        if (response) {
          navigate(ERouteLinks.Account);
        }
      }
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[{ label: 'User Settings', route: ERouteLinks.Account }, 'Edit User Data']}
      />

      <Box sx={{ mb: 1 }}>
        <StyledPageTitle variant="h4">Edit User Data</StyledPageTitle>
      </Box>

      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container>
          <Grid item lg={6} sm={12} xs={12}>
            <Grid item xs={12}>
              <Box mb={1}>
                <FormInput
                  name="email"
                  label="Username"
                  placeholder="Email"
                  disabled={true}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  isClearable
                />
              </Box>
            </Grid>

            <Grid container item xs={12} columnSpacing={2}>
              <Grid item xs={6}>
                <FormInput
                  name="firstName"
                  label="First Name*"
                  placeholder="First Name"
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  isClearable
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  name="lastName"
                  label="Last Name*"
                  placeholder="Last Name"
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  isClearable
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} columnSpacing={2}>
              <Grid item xs={6} md={3} lg={4}>
                <FormSelect
                  name="prefix"
                  options={mapDictionaryToOptionProp(phoneCode)}
                  label="Phone Prefix*"
                  setValue={setValue}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={6} md={9} lg={8}>
                <FormInput
                  name="phoneNumber"
                  label="Phone Number*"
                  placeholder="Phone Number"
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  isClearable
                />
              </Grid>
            </Grid>

            <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
              <Grid item xs={12} sm={6} lg={6}>
                <Button
                  type="submit"
                  fullWidth
                  label="Save"
                  isLoading={isSubmitLoading}
                  variant={EButtonVariants.contained}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={6}>
                <Button
                  fullWidth
                  label="Cancel"
                  variant={EButtonVariants.outlined}
                  onClick={() => navigate(ERouteLinks.Account)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} item mt={2}>
            <Typography variant="caption">*Mandatory field</Typography>
          </Grid>
          <Grid item mt={3} xs={12}>
            {samePageAlert && (
              <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export { EditAccountPage };
