import React, { useEffect, useState } from 'react';
import {
  initialTranslationFormValues,
  ISystemKeysFormValues,
  ITranslationFormValues,
} from 'models/form';
import { FormInput } from 'components/molecules/FormInput';
import Button from 'components/atoms/Button';
import { EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { colors } from 'theme/colors';
import { EIconTypes } from 'constants/Icons';
import { EAlertVariants } from 'components/atoms/Alert';
import { ActionTypes } from 'state/actions/alert';
import { EMIMETypes } from 'constants/MIMETypes';
import { useAlert } from 'models/alertContext';
import UploadDropzoneField from 'components/atoms/UploadDropzoneField';
import { StyledHeading } from '../styles';

const MAX_FILE_SIZE = 3;

export interface ITranslationForm {
  initialFormValues?: ISystemKeysFormValues | null;
  onCancel?: () => void;
  onSubmit: (arg: ISystemKeysFormValues) => void;
}

const SystemKeysForm = ({ initialFormValues, onCancel, onSubmit }: ITranslationForm) => {
  const { setAlert, clearAlert } = useAlert();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ITranslationFormValues>({
    defaultValues: initialFormValues || initialTranslationFormValues,
  });
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [attachment, setAttachment] = useState<any>(null);
  const [clearFiles, setClearFiles] = useState(false);

  useEffect(() => {
    reset(initialFormValues || initialTranslationFormValues);
  }, [reset, initialFormValues]);

  useEffect(() => {
    if (attachment) {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    }
  }, [attachment]);

  const onSubmitForm = async () => {
    if (!attachment) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: 'No file has been uploaded.',
        variant: EAlertVariants.warning,
      });
      return false;
    }

    setIsSubmitLoading(true);

    const fileSizeInMB = attachment.size / 1024 / 1024;
    let formErrors = false;
    if (attachment && fileSizeInMB > MAX_FILE_SIZE) {
      formErrors = true;
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: `The file size limit has been exceeded. The file size cannot exceed ${MAX_FILE_SIZE}MB.`,
        variant: EAlertVariants.error,
      });
    }
    if (![EMIMETypes.CSV, EMIMETypes.EXCEL_CSV].includes(attachment.type)) {
      formErrors = true;
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: 'The file type is not correct. Only csv files are allowed.',
        variant: EAlertVariants.error,
      });
    }
    if (!formErrors) {
      await onSubmit({
        translationsFile: attachment,
      });
    }
    setIsSubmitLoading(false);
    return false;
  };

  useEffect(() => {
    setValue('translationsFile', attachment ? attachment.name : '');
    setValue('uploadedFile', attachment ? attachment.name : '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachment]);

  const clearAttachment = () => {
    setClearFiles(true);
    setAttachment(null);
    setValue('translationsFile', '');
    setValue('uploadedFile', '');
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid container item rowSpacing={0} columnSpacing={2} lg={12}>
        <StyledHeading>
          <Typography variant="h5" color={colors.primary900}>
            System Keys – Translation File Upload
          </Typography>
        </StyledHeading>

        <Grid item container rowSpacing={2} lg={8}>
          <Grid item container rowSpacing={0} columnSpacing={2} xs={12}>
            <Grid item xs={12}>
              <UploadDropzoneField
                onUpload={(files: any) => {
                  setAttachment(files[0]);
                  setValue('uploadedFile', files[0].name);
                }}
                shouldClearFiles={clearFiles}
                setFilesCleared={setClearFiles}
              />
            </Grid>
          </Grid>
          <Grid item container columnSpacing={2} xs={12} sx={{ alignItems: 'center' }}>
            <Grid item xs="auto" sx={{ width: 'calc(100% - 48px) !important' }}>
              <FormInput
                name="uploadedFile"
                label="Uploaded File"
                control={control}
                errors={errors}
                disabled
              />
            </Grid>
            <Grid item xs="auto" sx={{ marginTop: '8px' }}>
              <Button
                icon={EIconTypes.delete}
                variant={EButtonVariants.outlined}
                sizePX={EButtonSizesPX.px32}
                onClick={() => clearAttachment()}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
          <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <Button
                type="submit"
                fullWidth
                label="Upload"
                variant={EButtonVariants.contained}
                isLoading={isSubmitLoading}
              />
            </Grid>

            {onCancel && (
              <Grid item xs={12} sm={6} lg={4}>
                <Button
                  fullWidth
                  label="Cancel"
                  variant={EButtonVariants.outlined}
                  onClick={onCancel}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export { SystemKeysForm };
