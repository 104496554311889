import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { Box, Typography } from '@mui/material';
import { ERouteLinks } from 'models/route';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchTrustedDevices, removeTrustedDevice } from 'requests/trustedDevice';
import { IAddTrustedDeviceResponse } from 'models/trustedDevice';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { ActionTypes } from 'state/actions/alert';
import { useAlert } from 'models/alertContext';
import { formatDate } from 'utils/date';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { StyledDeviceInfo } from './style';

const RemoveDevicePage = () => {
  const { id = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { setAlert } = useAlert();
  const [trustedDevice, setTrustedDevice] = useState<IAddTrustedDeviceResponse>();
  const { longDateFormat, getDateFormat } = useGlobalProperty();

  useEffect(() => {
    getDateFormat();
  }, []);

  useEffect(() => {
    (async function init() {
      if (id) {
        await fetchTrustedDevices((devices) => {
          devices.map((device) => {
            if (device.uniqueId === id) {
              setTrustedDevice(device);
            }
            return false;
          });
        });
      }
    })();
  }, [id]);

  const handleDelete = async () => {
    if (trustedDevice) {
      const result = await removeTrustedDevice(trustedDevice.uniqueId);
      if (result) {
        setAlert(ActionTypes.SET_TRUSTED_DEVICE_ALERT, {
          text: 'Trusted device has been removed successfully.',
          variant: EAlertVariants.success,
        });
        navigate(ERouteLinks.Account);
      }
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[{ label: 'User Settings', route: ERouteLinks.Account }, 'Remove Device']}
      />
      <Box sx={{ mb: 1 }}>
        <StyledPageTitle variant="h4">Remove Device</StyledPageTitle>

        <Box my={3}>
          <Alert
            text="Are you sure you want to remove the trusted device?"
            variant={EAlertVariants.warning}
          />
        </Box>
      </Box>

      <StyledDeviceInfo>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          Trusted Device
        </Typography>
        <Typography variant="body2">
          {trustedDevice?.title},{' '}
          {trustedDevice?.createdDate
            ? formatDate(trustedDevice.createdDate, longDateFormat)
            : ''}
        </Typography>
      </StyledDeviceInfo>

      <Grid container justifyContent="flex-start" sx={{ mt: 4 }}>
        <Grid item xs={12} lg={6} container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              label="Yes"
              variant={EButtonVariants.contained}
              sx={{ mr: 1 }}
              onClick={() => handleDelete()}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              label="No"
              variant={EButtonVariants.outlined}
              onClick={() => navigate(ERouteLinks.Account)}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export { RemoveDevicePage };
